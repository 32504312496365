.logoBox{
  padding-top: 20%;
  padding-bottom: 10%;
}

.gLogin {
  max-width: 300px;
  min-width: 100px;
  margin: 0 auto;
}
