.csvDownload {
  text-align: left;
  width: 240px;
  margin: 50px auto 0px auto;
}

.csvDownloadButtom{
  text-align: left;
  width: 240px;
}

.downloadProgress{
  width: 160px;
  margin: 0 auto;
  display: block;
}

/* Sync BM COmponenet */
.syncBm {
  text-align: center;
  width: 240px;
  margin: 20px auto 0px auto;
}

.syncBmButton{
}

.syncBmMessage{
  display:block;
  width: 200px;
  text-align: left;
  margin: 5px auto;
}
