.container {
  min-height: 94vh; /* will cover the 94% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
}

.footer {
 position: absolute;
 bottom: 0;
 width: 100%;
}
